import { Component, Input, Optional } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-progress-distractor',
    templateUrl: './progress-distractor.component.html',
    styleUrls: ['./progress-distractor.component.scss'],
    imports: [
        NgIf,
        MatProgressSpinnerModule,
        MatIconModule,
        MatButtonModule
    ],
    standalone: true
})
export class ProgressDistractorComponent {
    @Input() isDestroying = false;

    constructor(
        @Optional() public dialog: MatDialogRef<any>
    ) {
    }
}
